import React from 'react'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from '../../../common/Button'
import {
  darkBlue,
  electricBlue,
  coolGray50,
  whiteHover,
  white,
  BREAKPOINT_S
} from '../../../../constants'
import { ITrigger } from '.'

export const Trigger = styled(PrimaryButton)<{
  active: boolean
  onClick: (e: any) => void
  children: React.ReactNode
  overflowEllipsis: boolean
}>`
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius 20px;
  border: solid 2px ${coolGray50};
  background-color: ${props => (props.active ? whiteHover : white)};
  border-color: ${props => (props.active ? electricBlue : 'none')};
  @media (max-width: ${BREAKPOINT_S - 1}px) {
    padding: 8px 10px;
    font-size: 12px;
  }
  &:hover {
    border-color: ${props => (props.active ? electricBlue : darkBlue)};
  }
  &:focus-visible {
    border-color: ${electricBlue};
  }
  &:focus {
    outline: none;
  }
  &.trigger-active {
    border: solid 2px ${electricBlue};
    outline: none;
  }

  ${props =>
    props.overflowEllipsis
      ? `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: inline-block;
  `
      : ''}

`

export const StyledCircularIconTrigger = styled(SecondaryButton)`
  justify-content: center;
  padding: 7px;
  border-radius: 50%;
  border-color: ${white};
  width: 32px;
  height: 32px;

  &:hover {
    border-color: ${coolGray50};
    background-color: ${coolGray50};
  }
`

export const IconTrigger: React.FC<ITrigger> = ({ onClick, children }) => {
  return <SecondaryButton onClick={onClick}>{children}</SecondaryButton>
}

export const CircularIconTrigger: React.FC<ITrigger> = ({ onClick, children }) => {
  return <StyledCircularIconTrigger onClick={onClick}>{children}</StyledCircularIconTrigger>
}
