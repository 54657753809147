import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FilterPopover } from '../FilterPopover'
import { BedsBathsSelect } from '../BedsBathsSelect'
import { useBreakpoint } from '../../../hooks/useBreakpoint'
import { BEDS_OPTIONS, BREAKPOINT_M } from '../../../constants'
import { IBedsBathsProps } from './'

const StyledBedsBaths = styled('div')`
  width: calc(100vw - 80px);

  @media (min-width: ${BREAKPOINT_M}px) {
    width: 480px;
  }
`

export const BedsBaths: React.FC<IBedsBathsProps> = ({
  bedsMin,
  selectBedsMin,
  bedsMax,
  selectBedsMax,
  bathsMin,
  selectBathsMin,
  clearBedsBathsFilters,
  applyBedBathFilters,
  mobile
}) => {
  const INITIAL_TRIGGER_LABEL = 'Beds & baths'
  const [hasFilters, setHasFilters] = useState(false)
  const [triggerLabel, setTriggerLabel] = useState<string>(INITIAL_TRIGGER_LABEL)
  const { desktop } = useBreakpoint()

  const buildBedsLabel = () => {
    const highPlusBeds = BEDS_OPTIONS[BEDS_OPTIONS.length - 1]
    const maxFilterExists = !!bedsMax.value && bedsMax.value !== highPlusBeds.value
    if (bedsMin.value !== 0 && bedsMin.value === bedsMax.value) {
      return bedsMin.value === 1 ? `${bedsMin.value} bed` : `${bedsMin.value} beds`
    } else if (!!bedsMin.value && !!bedsMax.value && maxFilterExists) {
      return `${bedsMin.value} - ${bedsMax.value} beds`
    } else if (!!bedsMin.value && !maxFilterExists) {
      return `${bedsMin.value}+ beds`
    } else if (!bedsMin.value && maxFilterExists) {
      return `Up to ${bedsMax.value} beds`
    }
    return ''
  }

  const getTriggerLabel = () => {
    let separator = ''
    const beds = buildBedsLabel()
    const baths = !!bathsMin.value ? `${bathsMin.value}+ baths` : ''

    if (!(!!beds && !!baths)) {
      separator = INITIAL_TRIGGER_LABEL
    }
    if (!!beds || !!baths) {
      separator = ''
    }
    if (!!beds && !!baths) {
      separator = ', '
    }

    return `${beds}${separator}${baths}`
  }

  useEffect(() => {
    const label = getTriggerLabel()
    const hasFilters = bedsMin.value !== 0 || bedsMax.value !== 0 || bathsMin.value !== 0

    setTriggerLabel(label)
    setHasFilters(hasFilters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bedsMin, bedsMax, bathsMin])

  return (
    <FilterPopover
      triggerLabel={triggerLabel}
      testLabel="beds-baths-popover"
      hasSelectedFilters={hasFilters}
      clearFilters={clearBedsBathsFilters}
      applyFilters={applyBedBathFilters}
      popoverBodyWidth={mobile ? 'calc(100vw - 32px)' : ''}
      popoverBodyLeft={desktop ? '-175px' : '16px'}
      popoverBodyRight={'16px'}
      bodyMargin={'4px auto auto auto'}
      overflowEllipsis={true}
      popoverContainerMinWidth={'0'}
    >
      <StyledBedsBaths>
        <BedsBathsSelect
          bedsMin={bedsMin}
          selectBedsMin={selectBedsMin}
          bedsMax={bedsMax}
          selectBedsMax={selectBedsMax}
          bathsMin={bathsMin}
          selectBathsMin={selectBathsMin}
          applyFilters={applyBedBathFilters}
        />
      </StyledBedsBaths>
    </FilterPopover>
  )
}
